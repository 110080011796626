// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start()
Turbolinks.start()
ActiveStorage.start()

require.context('../images/', true)

import '../stylesheets/application'

import UIkit from 'uikit'

import "chartkick/chart.js"
import printJS from 'print-js'
import IMask from 'imask'

const Icons = require('uikit/dist/js/uikit-icons')
UIkit.use(Icons)

window.jQuery = $;
window.$ = $;

// fixes bug with not properly closing uikit modal
document.addEventListener("turbolinks:load", () => {
  var html = document.documentElement;
  if (html.classList.contains("uk-modal-page")) {
    // UIkit.modal("#mobile-menu").hide()  
  }
})
